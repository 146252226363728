<template>
  <div class="contenedor-imagenes">
    <img
      src="https://d24g0foqntlzx0.cloudfront.net/icons/homeIcons/LiquidacionesPropiedad.svg"
      alt="hombre"
      class="imagenderecha"
    />
    <a href="https://luque.munienlinea.com/login" target="_blank">
      <!-- <div class="imagenChica"></div> -->
      <img
        class="imagenChica"
        src="https://d24g0foqntlzx0.cloudfront.net/icons/homeIcons/oficinaVirtual_chica.svg"
        alt=""
      />
    </a>
  </div>
</template>
<script>
export default {
  name: "PromocionesComponent",
};
</script>
<style scoped>
.contenedor-imagenes {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  width: 963px;
  height: auto;
  /* margin-top: 3%; */
  gap: 53px;
  margin: auto;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.imagenChica {
  width: 456px;
  height: 241px;
  border-radius: 25px;
  object-fit: cover;
  background-size: cover;
}
.imagenderecha {
  width: 456px;
  height: 241px;
  /* border-radius: 30.356px; */
  object-fit: cover;
  height: -webkit-fill-available;
}
/*.imagenIzquierda {
  width: 557px;
  height: 250px;
  border-radius: 30.356px;
  object-fit: fill;
}*/
@media (max-width: 1360px) {
  .contenedor-imagenes {
    width: 70%;
    height: auto;
    gap: 5%;
  }
  .imagenderecha {
    width: 47%;
    height: 100%;
  }
  .imagenChica {
    width: 98%;
    height: auto;
    object-fit: cover;
  }
}
@media (max-width: 770px) {
  .contenedor-imagenes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 325px;
    height: auto;
    /* justify-content: space-between; */
    /* margin-top: 3%; */
    gap: 20px;
    margin: auto;
    margin-top: 20px;
    justify-content: center;
    overflow: hidden;
  }
  .imagenderecha {
    width: 100%;
    height: 170px;
    /* border-radius: 15px; */
    object-fit: fill;
  }
  .imagenChica {
    width: 100%;
    height: 170px;
    background-size: cover;
    /*object-fit: fill;*/
    border-radius: 15px;
  }
  a {
    width: 100%;
  }
}
</style>
