<template>
  <!-- <Carousel
    :itemsToShow="3"
    :wrapAround="true"
    :transition="0"
    class="contenedor"
  >
    <Slide v-for="slide in this.noticias" :key="slide">
      <div class="interno">
        <img :src="slide.imageUrl" alt="imagen" />

        <div class="interior-titulo" style="text-align: left">
          <router-link to="/novedades">
            <p class="fecha" style="font-weight: 700; font-size: 13px">
              {{ new Date(slide.createdAt).toLocaleDateString() }}
            </p>
            <p>{{ truncateText(slide.title, 70) }}</p>
          </router-link>
        </div>
      </div>

    </Slide>
    <template #addons>
      <Navigation />
    </template>
  </Carousel> -->
  <!-- COMPONENTE DESKTOP -->
  <div class="general">
    <div class="novedades-container">
      <div v-for="slide in this.noticias" :key="slide">
        <div class="tarjeta">
          <router-link :to="`/novedades/${slide.title}/${slide.id}`">
            <div class="imagen">
              <img :src="slide.imageUrls[0]" alt="imagen" />
            </div>
            <p style="color: var(--grisOscuroPrimario)">
              {{ truncateText(slide.title, 70) }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="masNovedades">
      <router-link to="/novedades">
        <p style="color: var(--grisOscuroPrimario)">
          Ingresá aquí para ver todas las novedades
        </p>
      </router-link>
    </div>
  </div>
  <!-- COMPONENTE MOBILE -->
  <div class="grid">
    <div v-for="slide in this.notimobile" :key="slide">
      <router-link
        :to="`/novedades/${slide.title}/${slide.id}`"
        class="tarjeta"
      >
        <img :src="slide.imageUrls[0]" alt="imagen" />
        <div>
          <h5 class="titulo" style="color: var(--grisOscuroPrimario)">
            {{ slide.title }}
          </h5>
          <p
            style="color: var(--grisOscuroPrimario)"
            v-html="truncateText(slide.content, 150)"
          ></p>
        </div>
      </router-link>
    </div>
    <div class="masNovedades">
      <router-link to="/novedades">
        <p style="color: var(--grisOscuroPrimario)">
          Ingresá aquí para ver todas las novedades
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
// import { Carousel, Slide, Navigation } from "vue3-carousel";
import axios from "axios";
import "vue3-carousel/dist/carousel.css";
import { BASE_URL } from "@/env";

export default {
  name: "CarouselComponent",
  components: {
    // Carousel,
    // Slide,
    // Pagination,
    // Navigation,
  },
  data() {
    return {
      noticias: [],
      position: 0,
      notimobile: "",
    };
  },
  created() {
    this.getNoticias();
    window.scrollTo(0, 0);
    // this.paginacion();
    // this.novedadesB = this.novedades.slice(this.inicio, this.final);
  },
  methods: {
    handleTransitionEnd() {
      const totalSlides = this.noticias.length;

      if (this.currentSlideIndex === totalSlides) {
        // Si está en el último slide, salta al primero sin animación
        this.$refs.carousel.goToSlide(0, false); // El segundo parámetro desactiva la transición
      } else if (this.currentSlideIndex === -1) {
        // Si está en el primer slide y va hacia atrás, salta al último sin animación
        this.$refs.carousel.goToSlide(totalSlides - 1, false);
      }
    },
    getNoticias() {
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient.get("/news/all-news").then((response) => {
        //console.log("noticias", response.data);
        this.noticias = response.data.Noticias.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ).slice(0, 6);
        this.notimobile = response.data.Noticias.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ).slice(0, 3);
      });
      // .catch((error) => {
      //console.log(error);
      //});
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.general {
  width: 964px;
  height: auto;
  margin: auto;
  margin-bottom: 60px;
}
.novedades-container {
  display: grid;
  grid-template-columns: repeat(3, 310px);
  margin: auto;
  gap: 15px;
}
.tarjeta {
  width: 310px;
  height: 263px;
  border-radius: 19.8px;
  background: #fff;
  box-shadow: 0px 3.171px 3.171px 0px rgba(0, 0, 0, 0.25);
  padding: 23px 20px;
}
.imagen {
  height: 192px;
  width: 100%;
  overflow: hidden;
  /* object-fit: cover; */
  border-radius: 10px;
}
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
p {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-top: 5px;
}
.masNovedades {
  width: 332px;
  height: 42px;
  margin: auto;
  border-radius: 25.707px;
  background: #fff;
  margin-top: 60px;
  text-align: center;
  padding: 5px;
  /* padding: 8px; */
}
a {
  text-decoration: none;
}
/* .contenedor {
  width: 847px;
  height: 100%;
  margin: auto;
  margin-bottom: 60px;
  padding: 0 2rem;
}
.carousel__slide {
  padding: 0px;
  background: #fff;
  height: 300px;
  width: auto;
  border-radius: 16px;
  box-shadow: 0px 3.213px 8.033px 0px rgba(97, 97, 97, 0.1);
  margin: auto;
}
.interno {
  padding: 10px 14px;
  background: #fff;
  height: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
  margin: auto;
  justify-content: flex-start;
}
.carousel__slide:hover {
  background: var(--bordeDegradeCompleto);
  padding: 2px;
}
a {
  color: #4b4a49;
  text-decoration: none;
}
a:hover {
  color: #333232;
}
img {
  height: 155px;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
}
.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
  transition: none !important;
}

::v-deep(.carousel__prev) {
  margin: 0 0 !important;
}

::v-deep(.carousel__next) {
  margin: 0 0 !important;
}

.carousel__slide--sliding {
  transition: 0;
}

.carousel__slide {
  opacity: 0.5;
  transform: scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-30deg) scale(0.95);
  height: 239.548px;
}
::v-deep(.carousel__track) {
  transition: none;
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(50deg) scale(0.95);
  height: 239.548px;
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(25deg) scale(1.1);
  height: 263.072px;
  margin: 2rem 3rem;
}
.interior-titulo {
  width: 100%;
  text-align: left;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.interior-titulo p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} */
.fecha {
  margin-bottom: 5px;
}
.grid {
  display: none;
}
@media (max-width: 1360px) {
  .general {
    width: 70%;
  }
  .novedades-container {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    margin: auto;
    gap: 15px;
  }
  .tarjeta {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 1000px) {
  .tarjeta {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3;
  }
}
@media (max-width: 770px) {
  .general {
    display: none;
  }
  .grid {
    /* display: grid;
    grid-template-columns: auto;
    gap: 20px;
    width: 297px; */
    width: 325px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
  }
  .tarjeta {
    width: 100%;
    height: 134px;
    border-radius: 10.11px;
    background: #fff;
    box-shadow: 0px 1.619px 1.619px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 18px;
    /* overflow: hidden; */
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
  }
  img {
    height: 108px;
    width: 137px;
    object-fit: cover;
    border-radius: 5.26px;
  }
  .titulo {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
  p {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
  .masNovedades {
    width: 258px;
    height: 30px;
    margin: auto;
    border-radius: 25.707px;
    background: #fff;
    margin-top: 20px;
    text-align: center;
    padding: 2px;
  }
  .masNovedades p {
    font-weight: 700;
  }
  /* .tarjeta {
    height: 300px;
    width: 100%;
    border-radius: 16px;
    background: #fff;
    padding: 14px;
    display: flex;
    flex-direction: column;
    gap: 21px;
  } */
  /* img {
    width: 100%;
    border-radius: 16px;
    object-fit: fill;
  } */
  /* .interior-titulo {
    margin-top: 0px;
    margin: 0;
  } */
  /* .fecha {
    font-size: 19px;
  } */
  /* .contenedor {
    height: 195px;
    width: 90%;
  }
  .slide {
    height: 100%;
    width: 100%;
    display: block;
  }

  .carousel__slide {
    height: 142px;
    padding: 0;
    border-radius: 16px;
  }
  .carousel__slide--active {
    height: 142px;
    margin: 1.5rem 2.5rem;
    padding-top: 3px;
  }

  .interior-titulo p {
    font-size: 8px;
  }
  .interior-titulo {
    padding-left: 0.5rem;
  }
  img {
    height: 45%;
    width: 95%;
    border-radius: 16px;
    object-fit: contain;
  } */
}
</style>
