<template>
  <nav class="contenedor-nav">
    <div class="internoContenedor">
      <div style="display: flex; flex-direction: row; gap: 17px">
        <i class="bi bi-list burguer" @click="this.setMenu"> </i>
        <router-link to="/">
          <img src="@/assets/images/LogoLuque.svg" alt="" class="logo-muni" />
        </router-link>
      </div>

      <div class="botones">
        <div class="dropdown">
          <button
            class="btn btn dropdown-toggle asd"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            LA CIUDAD
          </button>
          <ul class="dropdown-menu">
            <li>
              <router-link to="/ciudad/historia" class="link">
                <a class="dropdown-item hoverMenu" href="#">Nuestra historia</a>
              </router-link>
            </li>
            <li>
              <router-link to="/ciudad/luque-hoy" class="link">
                <a class="dropdown-item hoverMenu" href="#">Luque hoy</a>
              </router-link>
            </li>
            <li>
              <router-link to="/ciudad/descubri-luque" class="link">
                <a class="dropdown-item hoverMenu" href="#">Descubrí Luque</a>
              </router-link>
            </li>
            <li>
              <router-link to="/ciudad/festejos-populares" class="link">
                <a class="dropdown-item hoverMenu" href="#">
                  Festejos Populares
                </a>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="lineaVertical1"></div>
        <div class="dropdown">
          <button
            class="btn btn dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            NUESTRO GOBIERNO
          </button>
          <ul class="dropdown-menu">
            <li>
              <router-link to="/gobierno/quienes-somos" class="link">
                <a class="dropdown-item hoverMenu" href="#">¿Quiénes somos?</a>
              </router-link>
            </li>
            <li>
              <router-link to="/gobierno/gestion" class="link">
                <a class="dropdown-item hoverMenu" href="#">La Gestión</a>
              </router-link>
            </li>
            <li>
              <router-link to="/gobierno/logros" class="link">
                <a class="dropdown-item hoverMenu" href="#">Logros</a>
              </router-link>
            </li>
            <li>
              <router-link to="/gobierno/transparencia-municipal" class="link">
                <a class="dropdown-item hoverMenu" href="#"
                  >Transparencia Municipal</a
                >
              </router-link>
            </li>
          </ul>
        </div>
        <div class="lineaVertical2"></div>

        <div class="dropdown">
          <button
            class="btn btn dropdown-toggle asd"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            TRÁMITES Y SERVICIOS
          </button>
          <ul class="dropdown-menu">
            <li>
              <router-link
                v-if="impuestos.impuestos"
                :to="`/tramites-servicios/pagar-tasas?categoryTitle=${this.$store.state.categorias.impuestos[0].title}&categoryId=${this.$store.state.categorias.impuestos[0].id}`"
                class="link"
              >
                <a class="dropdown-item hoverMenu" href="#">Pagar Tasas</a>
              </router-link>
            </li>
            <li>
              <router-link to="/tramites-servicios/guia-tramites" class="link">
                <a class="dropdown-item hoverMenu" href="#">Guía de Trámites</a>
              </router-link>
            </li>
            <li>
              <router-link
                v-if="impuestos.impuestos"
                :to="`/tramites-servicios/guia-tramites/tasas-e-impuestos?categoryTitle=${this.$store.state.categorias.impuestos[0].title}&categoryId=${this.$store.state.categorias.impuestos[0].id}`"
                class="link"
              >
                <a class="dropdown-item hoverMenu" href="#"
                  >Portal Tributario</a
                >
              </router-link>
            </li>
            <li>
              <router-link
                v-if="impuestos.transito"
                :to="`/tramites-servicios/guia-tramites/transito?categoryTitle=${this.$store.state.categorias.transito[0]?.title}&categoryId=${this.$store.state.categorias.transito[0]?.id}&categoryTitleDos=${this.$store.state.categorias.transito[1]?.title}&categoryIdDos=${this.$store.state.categorias.transito[1]?.id}`"
                class="link"
              >
                <a class="dropdown-item hoverMenu" href="#"
                  >Portal de Tránsito</a
                >
              </router-link>
            </li>
            <li>
              <a
                href="https://luque.munienlinea.com/login"
                target="_blank"
                class="dropdown-item hoverMenu link"
                >Ingresar a Oficina Virtual</a
              >
            </li>
          </ul>
        </div>
        <div class="lineaVertical3"></div>

        <div class="dropdown">
          <router-link to="/novedades" class="link">
            <button class="btn btn dropdown link" type="button">
              NOVEDADES
            </button>
          </router-link>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item hoverMenu" href="#">Últimas Noticias</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- MENU-BURGUER -->
      <div class="menu" v-if="this.menu && ListMenu.length > 0">
        <div
          class="item-menu"
          @click="this.setSubMenu(index)"
          v-for="(item, index) in this.ListMenu"
          :key="index"
        >
          <h6>
            {{ item.titulo }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M2.1875 3.59375L5 6.40625L7.8125 3.59375"
                stroke="#3E3E3E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </h6>
          <div
            class="item-subMenu"
            v-if="this.open === true && this.itemSelect === index"
          >
            <div v-for="(item, index) in item.subtitulos" :key="index">
              <div
                v-if="item.titulo === 'Ingresar a Oficina Virtual'"
                style="margin-top: -7px; margin-left: 16px"
              >
                <a
                  href="https://luque.munienlinea.com/"
                  class="link"
                  target="_blank"
                >
                  {{ item.titulo }}</a
                >
              </div>
              <div v-else>
                <router-link
                  :to="item.link"
                  class="link"
                  @click="this.setMenu"
                  :key="index"
                >
                  <h6>
                    {{ item.titulo }}
                  </h6>
                </router-link>
              </div>
            </div>
            <!-- <router-link
              v-for="(item, index) in item.subtitulos"
              :to="item.link"
              class="link"
              @click="this.setMenu"
              :key="index"
            >
              <h6>
                {{ item.titulo }}
              </h6>
            </router-link> -->
          </div>
          <div class="linea-menu"></div>
        </div>
      </div>

      <div class="redes-container">
        <a href="http://wa.me/3573413371" target="_blank">
          <img class="redes" src="@/assets/images/Wpp.svg" alt="" />
        </a>
        <a
          href="https://www.instagram.com/municipalidad_de_luque/"
          target="_blank"
        >
          <img class="redes" src="@/assets/images/Instagram.svg" alt="" />
        </a>
        <a
          href="https://www.facebook.com/MunicipalidaddeLuque/"
          target="_blank"
        >
          <img class="redes" src="@/assets/images/Facebook.svg" alt="" />
        </a>
      </div>
    </div>
  </nav>
  <!-- <div class="linea"></div> -->
</template>
<script>
import axios from "axios";
import { BASE_URL } from "@/env";
export default {
  name: "topNavComponent",
  data() {
    return {
      valor: "",
      isSticky: false,
      temperatura: 0,
      tiempo: "",
      icono: "",
      menu: false,
      open: false,
      itemSelect: null,
      ListMenu: null,
      impuestos: {},
    };
  },
  created() {
    // window.addEventListener("scroll", this.handleScroll);
    this.getTiempo();
    this.getCategorias();
  },
  methods: {
    handleScroll() {
      // Verifica la posición de desplazamiento para determinar si se activa el sticky
      this.isSticky = window.scrollY > 100; // Puedes ajustar el valor según tus necesidades
    },
    categoriasState() {
      this.$store.dispatch("getCategoriasAction", this.impuestos);
    },
    getCategorias() {
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient.get("/oficina/categories/categories").then((response) => {
        // console.log(response.data, "estoy en el nbav");

        this.impuestos.impuestos = response.data.Categories.taxArray;
        this.impuestos.transito = response.data.Categories.licencesArray;
        this.impuestos.obras = response.data.Categories.workArray;
        this.categoriasState();
        this.ListMenu = [
          {
            titulo: "LA CIUDAD",
            subtitulos: [
              { titulo: "Nuestra Historia", link: "/ciudad/historia" },
              { titulo: "Luque Hoy", link: "/ciudad/luque-hoy" },

              { titulo: "Descubrí Luque", link: "/ciudad/descubri-luque" },

              {
                titulo: "Festejos Populares",
                link: "/ciudad/festejos-populares",
              },
            ],
          },
          {
            titulo: "NUESTRO GOBIERNO",
            subtitulos: [
              { titulo: "¿Quiénes somos?", link: "/gobierno/quienes-somos" },
              { titulo: "La Gestión", link: "/gobierno/gestion" },

              { titulo: "Logros", link: "/gobierno/logros" },

              {
                titulo: "Transparencia Municipal",
                link: "/gobierno/transparencia-municipal",
              },
            ],
          },
          {
            titulo: "TRÁMITES Y SERVICIOS",
            subtitulos: [
              {
                titulo: "Pagar tasas",
                link: `/tramites-servicios/pagar-tasas?categoryTitle=${this.impuestos?.impuestos[0]?.title}&categoryId=${this.impuestos?.impuestos[0]?.id}`,
              },
              {
                titulo: "Guia de Trámites",
                link: "/tramites-servicios/guia-tramites",
              },
              {
                titulo: "Portal Tributario",
                link: `/tramites-servicios/guia-tramites/tasas-e-impuestos?categoryTitle=${this.impuestos?.impuestos[0]?.title}&categoryId=${this.impuestos?.impuestos[0]?.id}`,
              },
              {
                titulo: "Portal de Tránsito",
                link: `/tramites-servicios/guia-tramites/transito?categoryTitle=${this.impuestos?.transito[0]?.title}&categoryId=${this.impuestos?.transito[0]?.id}&categoryTitleDos=${this.impuestos?.transito[1]?.title}&categoryIdDos=${this.impuestos?.transito[1]?.id}`,
              },
              { titulo: "Ingresar a Oficina Virtual", link: "#" },
            ],
          },
          {
            titulo: "NOVEDADES",
            subtitulos: [{ titulo: "Novedades", link: "/novedades" }],
          },
        ];
        // for (let index = 0; index < 4; index++) {
        //   this.gestiones.push(this.categorias[index]);
        // }
        this.loading = false;
      });
      //.catch((error) => {
      //  console.log(error);
      // });
    },
    getTiempo() {
      axios
        .get(
          "https://api.tutiempo.net/json/?lan=es&apid=a5GX44aXXaahzCV&ll=-31.6476686,-63.3444"
        )
        .then((response) => {
          //console.log(response);
          this.temperatura = response.data.hour_hour.hour1.temperature;
          this.tiempo = response.data.hour_hour.hour1.text;
          let icon = response.data.hour_hour.hour1.icon;
          // let dia = new Date();
          // this.dia = dia.getDate();
          // this.getDia(dia.getDay());
          // this.getMes(dia.getMonth());
          // this.text = asd.split(" ");
          // this.text = this.text[0].toUpperCase();
          // this.text = asd.toUpperCase();

          this.icono = `https://v5i.tutiempo.net/wi/02/30/${icon}.png`;
        });
    },
    setMenu() {
      this.menu = !this.menu;
    },
    setSubMenu(index) {
      //console.log("soy el indice");
      this.itemSelect = index;
      this.open = !this.open;
      // this.ListMenu[index].open = true;
    },
  },
};
</script>

<style scoped>
/* .bi {
  display: none;
} */
/* nav {
  width: 100%;
} */
.dropdown a {
  font-weight: 500;
  font-size: 15px;
}

.burguer {
  display: none;
}
.logo-muni {
  display: flex;
  width: 181.185px;
  height: 55.019px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.logo-mobile {
  /* visibility: hidden; */
  display: none;
}

.contenedor-nav {
  position: fixed;
  width: 100%;
  height: 100px;
  /* display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 34px;
  background: #fff;
  z-index: 5; */
  padding-bottom: 4px;
  background: var(--verdeDegrade);
  z-index: 5;
}
.internoContenedor {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 34px;
  background: #fff;
  z-index: 5;
  width: 100%;
  height: 100%;
}

.botones {
  height: auto;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  margin: 50px 118px auto 118px;
  position: relative;
  z-index: 5;
  color: #3e3e3e;
}

.btn {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.5px;
  border: none;
  color: var(--grisOscuroPrimario);
}
.redes {
  width: 46px;
  height: 46px;
}

.redes-container {
  display: flex;
  width: 152px;
  justify-content: space-between;
  margin-top: 4px;
}

.hoverMenu:hover {
  color: #00c3a8;
}
.link {
  text-decoration: none;
}
/* .contenedor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
} */
.linea {
  height: 7.5px;
  background: var(--bordeDegradeCompleto);
  padding-top: 105px;
  z-index: 500;
}
.lineaVertical1 {
  width: 4.384px;
  height: 23.017px;
  border-radius: 33.977px;
  background: linear-gradient(
    165deg,
    #00c3a8 -2271.63%,
    #ffb900 1386.48%,
    #ff2745 4734.46%
  );
}
.lineaVertical2 {
  width: 4.384px;
  height: 23.017px;
  border-radius: 33.977px;
  background: linear-gradient(
    165deg,
    #00c3a8 -3377.88%,
    #ffb900 301.71%,
    #ff2745 3669.34%
  );
}
.lineaVertical3 {
  width: 4.384px;
  height: 23.017px;
  border-radius: 33.977px;
  background: linear-gradient(
    165deg,
    #00c3a8 -4545.27%,
    #ffb900 -868.32%,
    #ff2745 2496.89%
  );
}

.menu {
  display: none;
}
/* @media (max-width: 1200px) {
  .botones {
    width: 100%;
    margin: auto;
  }
}*/

@media (max-width: 1360px) {
  .contenedor-nav {
    width: 100%;
  }
  .botones {
    margin: auto;
  }
  .btn {
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.735px;
    border: none;
  }
  .logo-muni {
    display: flex;
    width: auto;
    height: 42px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .redes-container {
    display: flex;
    width: 120px;
    justify-content: space-between;
    margin-top: 4px;
  }
  .redes {
    width: 35px;
    height: 35px;
  }
  .internoContenedor {
    padding: 0px 15px;
  }
}
@media (max-width: 990px) {
  .contenedor-nav {
    width: 100%;
  }
  .logo-muni {
    height: 33px;
    width: 107px;
    margin-top: 5px;
  }
  /* .botones {
    display: none;
  } */
  /* .burguer {
    display: block;
    margin-left: 0px;
    font-size: 30px;
  } */
  /* .burguer {
    display: block;
    margin-left: 0px;
    font-size: 30px;
  } */
}
@media (max-width: 770px) {
  .contenedor-nav {
    width: 100%;
    height: 56px;
    z-index: 5;
    background: var(--bordeDegradeCompleto);
    padding-bottom: 2px;
    /* padding: 15px; */
  }
  .logo-muni {
    display: block;
  }
  .internoContenedor {
    padding: 15px;
    width: 100%;
    height: 100%;
  }
  .botones {
    display: none;
  }

  .burguer {
    display: block;
    margin-left: 0px;
    font-size: 30px;
  }
  .redes-container {
    width: 90px;
  }
  .redes {
    height: 28px;
    width: 28px;
    display: block;
  }
  .menu {
    display: block;
    width: 100%;
    background: #fff;
    position: absolute;
    top: 52px;
    left: 0px;
    padding: 10px 0px;
    border-top: 1px solid var(--grisMedio);
  }
  .item-menu {
    /* border-bottom: 1px solid var(--grisMedio); */
    padding: 10px 16px;
  }
  .item-menu h6 {
    font-size: 11px;
    font-weight: 600;
    margin-left: 16px;
  }
  .item-subMenu {
    padding: 8px;
  }
  .item-subMenu h6,
  a {
    font-size: 11.2px;
    font-style: normal;
    font-weight: 400;
    color: var(--texto);
  }
  .linea {
    display: none;
  }
  .linea-menu {
    width: 100%;
    height: 1px;
    background: var(--verdeDegrade);
  }
}
</style>
